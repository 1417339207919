.page {
    margin-block: 0px;
    padding: 0%;
    width: 100%;
    height: fit-content;
    background-color: rgb(75, 102, 196);
}

.layer {
    margin-block: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
}